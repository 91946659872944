import React from 'react';
import { cn } from '@aph/ui/tailwind/cn';

type MemberDataInputContainerProps = {
  label?: string;
  ref?: React.Ref<HTMLDivElement>;
  inputRef?: React.Ref<HTMLInputElement>;
  error?: boolean;
  helperText?: string | null;
} & React.ComponentPropsWithoutRef<'input'>;

export const MemberInput: React.FC<MemberDataInputContainerProps> = ({
  id,
  className,
  ref,
  inputRef,
  label: labelMessage,
  placeholder,
  error,
  helperText,
  ...props
}) => {
  return (
    <div className={className}>
      <div className="group relative" ref={ref}>
        <input
          ref={inputRef}
          placeholder=" "
          id={id}
          className={cn(
            'bg-default peer block w-full appearance-none border-b-[1px] p-1 pb-[0px] pl-1.5 pt-3',
            'focus:outline-none focus:brightness-95 enabled:hover:brightness-90 disabled:brightness-75',
            error ? 'border-b-error' : 'border-b-action-darkest',
          )}
          {...props}
          aria-invalid={error}
          aria-describedby={`${id}-error-message`}
        />
        {(labelMessage || placeholder) && (
          <label
            htmlFor={id}
            className={cn(
              'pointer-events-none absolute left-1.5 top-0.5 origin-top-left translate-y-[0px] text-sm transition-all',
              'peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base',
              'peer-focus:top-0.5 peer-focus:translate-y-[0px] peer-focus:text-sm',
              error
                ? 'text-error'
                : 'text-brand peer-focus:text-brand peer-placeholder-shown:text-subtle',
            )}
          >
            {labelMessage ?? placeholder}
          </label>
        )}
        <span
          className={cn(
            'absolute -bottom-[1px] left-0 w-full origin-center scale-0 border-b-[2px] transition-transform peer-focus:scale-100',
            error ? 'border-b-error' : 'border-b-action-darkest',
          )}
        />
      </div>
      <span
        className={`text-error pointer-events-none -mt-0.5 ml-1.5 flex h-2 select-none items-start text-xs italic ${!error && 'invisible'}`}
        id={`${id}-error-message`}
      >
        {helperText}
      </span>
    </div>
  );
};

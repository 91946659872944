import { container } from 'tsyringe';
import type { MemberDataV3 } from '~/services/generated/CustomerClient';
import { Client as CustomerClient } from '~/services/generated/CustomerClient';

// Gets the membership information about the currently logged in user
export const getMemberForMe = async (correlationId: string): Promise<MemberDataV3> => {
  const client = container.resolve(CustomerClient);
  const response = await client.getMemberForMeV3(correlationId, 'aphTinman', undefined, undefined);
  return response;
};

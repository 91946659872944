import type { LinkListProps } from '@aph/components/common/link-list/link-list.component';
import type { NavItem } from '@aph/components/common/navigator/navigator-items.utils';

export type NavItemVariant = LinkListProps['variant'];

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL_WEB;

const navItemsSort = (a: NavItem, b: NavItem) =>
  a.sortOrder && b.sortOrder && a.sortOrder >= b.sortOrder ? 1 : -1;

const sharedNavItems: Array<NavItem & { variant: NavItemVariant }> = [
  {
    name: 'CLUB_MEMBER.NAVIGATION_CLUB_OFFERS',
    url: '/klubb-hjartat/klubberbjudanden/',
    sortOrder: 20,
    variant: 'primary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_SHARED_BONUS',
    url: '/klubb-hjartat/gemensam-bonus/',
    sortOrder: 25,
    variant: 'primary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_MEMBER_OFFERS',
    url: '/klubb-hjartat/samarbetspartners-och-klubbformaner/',
    sortOrder: 30,
    variant: 'secondary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_MEMBERSHIP_CONDITIONS',
    url: '/klubb-hjartat/medlemsvillkor/',
    sortOrder: 35,
    variant: 'secondary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_SENIOR_AND_STUDENT_DISCOUNT',
    url: '/klubb-hjartat/senior--och-studentrabatt/',
    sortOrder: 27,
    variant: 'secondary',
  },
];

const memberNavItems: Array<NavItem & { variant: NavItemVariant }> = [
  {
    name: 'CLUB_MEMBER.NAVIGATION_CLUB_MEMBER_START',
    url: '/klubb-hjartat/start/',
    sortOrder: 5,
    variant: 'primary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_MY_DETAILS',
    url: '/klubb-hjartat/mina-uppgifter/',
    sortOrder: 6,
    variant: 'primary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_MY_BONUS_CHECKS',
    url: '/klubb-hjartat/mina-bonuscheckar/',
    sortOrder: 8,
    variant: 'primary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_ORDER_HISTORY',
    url: '/klubb-hjartat/orderhistorik/',
    sortOrder: 9,
    variant: 'primary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_LOGOUT',
    url: `${baseUrl}/logga-ut/`,
    sortOrder: 40,
    isEpiLink: true,
    variant: 'secondary',
  },
];

const notAuthenticatedNavItems: Array<NavItem & { variant: NavItemVariant }> = [
  {
    name: 'CLUB_MEMBER.NAVIGATION_CLUB_MEMBER',
    url: '/klubb-hjartat/mina-sidor/',
    sortOrder: 10,
    variant: 'primary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_BECOME_MEMBER',
    url: '/klubb-hjartat/bli-medlem/',
    sortOrder: 12,
    variant: 'primary',
  },
];

const notMemberNavItems: Array<NavItem & { variant: NavItemVariant }> = [
  {
    name: 'CLUB_MEMBER.NAVIGATION_BECOME_MEMBER',
    url: '/klubb-hjartat/bli-medlem/',
    sortOrder: 12,
    variant: 'primary',
  },
  {
    name: 'CLUB_MEMBER.NAVIGATION_LOGOUT',
    url: `${baseUrl}/logga-ut/`,
    sortOrder: 40,
    isEpiLink: true,
    variant: 'secondary',
  },
];

export const notLoggedInNavItems = [...sharedNavItems, ...notAuthenticatedNavItems].sort(
  navItemsSort,
);

export const loggedInNotMemberNavItems = [...sharedNavItems, ...notMemberNavItems].sort(
  navItemsSort,
);

export const loggedInMemberNavItems = [...sharedNavItems, ...memberNavItems].sort(navItemsSort);

export const allNavItems = [...sharedNavItems, ...memberNavItems, ...notAuthenticatedNavItems];
